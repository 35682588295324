import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { SDK_API_SERVER } from "../../constant";
import CustomSnackbar from "../../commonComponent/CustomSnackbar/CustomSnackbar";
import { useNavigate } from "react-router-dom";
import Storage from "../../helperFunctions/Storage";

const urbitLogo = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="47"
    height="44"
    viewBox="0 0 47 44"
    fill="none"
  >
    <path
      d="M26.5703 32.4536C26.5703 31.4136 25.7272 30.5705 24.6872 30.5705H22.2935C21.2535 30.5705 20.4104 31.4136 20.4104 32.4536L20.4104 41.3707C20.4104 42.4107 21.2535 43.2538 22.2935 43.2538L24.6872 43.2538C25.7272 43.2538 26.5703 42.4107 26.5703 41.3707V32.4536Z"
      fill="black"
    />
    <path
      d="M14.8167 40.4577L10.5955 37.9857C9.66053 37.4382 9.37933 36.2166 9.98081 35.3154L15.949 26.3733C16.2986 25.8496 16.8868 25.5352 17.5165 25.5356L29.5185 25.5436C30.1467 25.5441 30.7334 25.8578 31.0826 26.38L37.0549 35.3118C37.6583 36.2142 37.3759 37.4384 36.4382 37.9852L32.2177 40.4464C31.2934 40.9853 30.1065 40.647 29.6053 39.7017L25.3208 31.6201C24.9942 31.0042 24.3542 30.6191 23.657 30.6191L23.3749 30.6191C22.6769 30.6191 22.0363 31.0051 21.7101 31.6221L17.4331 39.7128C16.932 40.6606 15.7419 40.9995 14.8167 40.4577Z"
      fill="black"
    />
    <path
      d="M32.7607 12.203C31.8935 12.7771 31.6561 13.9456 32.2303 14.8127L33.5518 16.8085C34.126 17.6756 35.2945 17.913 36.1616 17.3388L43.5964 12.4156C44.4635 11.8414 44.701 10.673 44.1268 9.80586L42.8052 7.81009C42.231 6.94297 41.0626 6.70551 40.1955 7.2797L32.7607 12.203Z"
      fill="black"
    />
    <path
      d="M45.939 17.6068L46.2085 22.4912C46.2682 23.573 45.4049 24.482 44.3214 24.478L33.5707 24.4389C32.941 24.4366 32.3541 24.1197 32.0068 23.5945L25.3871 13.5832C25.0405 13.0592 24.9782 12.3968 25.2208 11.8173L29.3705 1.90638C29.7897 0.905121 30.9664 0.464687 31.94 0.944606L36.3223 3.1047C37.2819 3.57772 37.6551 4.75421 37.1437 5.69392L32.7711 13.7282C32.4378 14.3405 32.4701 15.0868 32.855 15.6681L33.0108 15.9033C33.3961 16.4852 34.0717 16.8063 34.7662 16.7376L43.8734 15.8366C44.9403 15.7311 45.8799 16.5363 45.939 17.6068Z"
      fill="black"
    />
    <path
      d="M14.2398 12.203C15.1069 12.7771 15.3444 13.9456 14.7702 14.8127L13.4486 16.8085C12.8744 17.6756 11.706 17.913 10.8389 17.3388L3.40408 12.4156C2.53696 11.8414 2.2995 10.673 2.87369 9.80586L4.19527 7.81009C4.76947 6.94297 5.93789 6.70551 6.805 7.2797L14.2398 12.203Z"
      fill="black"
    />
    <path
      d="M1.0615 17.6068L0.791982 22.4912C0.732287 23.573 1.59559 24.482 2.67906 24.478L13.4298 24.4389C14.0595 24.4366 14.6464 24.1197 14.9937 23.5945L21.6134 13.5832C21.9599 13.0592 22.0223 12.3968 21.7796 11.8173L17.63 1.90638C17.2108 0.905121 16.0341 0.464687 15.0605 0.944606L10.6782 3.1047C9.71858 3.57772 9.34534 4.75421 9.85678 5.69392L14.2294 13.7282C14.5627 14.3405 14.5304 15.0868 14.1455 15.6681L13.9897 15.9033C13.6044 16.4852 12.9288 16.8063 12.2343 16.7376L3.12711 15.8366C2.06019 15.7311 1.12057 16.5363 1.0615 17.6068Z"
      fill="black"
    />
    <path
      d="M27.5098 20.2518C27.5098 22.4662 25.7147 24.2613 23.5003 24.2613C21.2859 24.2613 19.4908 22.4662 19.4908 20.2518C19.4908 18.0374 21.2859 16.2423 23.5003 16.2423C25.7147 16.2423 27.5098 18.0374 27.5098 20.2518Z"
      fill="black"
    />
  </svg>
);

const DemoLogin = () => {
  const [urbitKey, setUrbitKey] = useState("");
  const [urbitId, setUrbitId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [cookie, setCookie] = useState("");
  const [loading, setLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarKey, setSnackbarKey] = useState(0);

  const navigate = useNavigate();

  const _chechUrbitLogin = async () => {
    try {
      let errorMessage_ = "";
      if (urbitId === "") {
        errorMessage_ = "Please Enter Urbit Id";
        setErrorMessage(errorMessage_);
        setSnackbarMessage(errorMessage_);
        setSnackbarKey((prevKey) => prevKey + 1);
        return;
      }
      if (urbitKey === "") {
        errorMessage_ = "Please Enter Urbit Credential";
        setErrorMessage(errorMessage_);
        setSnackbarMessage(errorMessage_);
        setSnackbarKey((prevKey) => prevKey + 1);
        return;
      }
      var config = {
        method: "post",
        url: SDK_API_SERVER + "/api/urbit-login",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: "id=" + urbitId + "&key=" + urbitKey,
      };
      const response = await axios(config);
      if (response != null && response.status === 200) {
        const data = response.data;
        console.log("response.data===>", response.data);

        console.log("ffsfsfsffsffsffffffffff");
        console.log("data.data==>", data.data);
        // Storage.sset("cookie", data.data[0].cookie);
        // setShip(data.data[0].ship);
        setCookie(data.data[0].cookie);
        // setUrbitId(data.data[0].text);

        console.dir(data);
        if (
          data.status === 1 &&
          (data.data[0].status === 204 || data.data[0].status === 200)
        ) {
          return { response: 1, data: data.data };
        } else if (data.status === 1 && data.data[0].status === 400) {
          return { response: 0 };
        }
      }
    } catch (e) {}
    return 0;
  };

  const __submit1 = async () => {
    setLoading(true);
    setErrorMessage("");
    let errorMessage_ = "";
    const iresponse = await _chechUrbitLogin();
    //alert(iresponse);
    console.log("iresponse===>", iresponse);
    if (iresponse?.response != 1) {
      // alert("Invalid Urbit Login");
      // lo
      console.log("in errpr");
      errorMessage_ = "Invalid Urbit Login";
      setErrorMessage(errorMessage_);
      setSnackbarMessage(errorMessage_);
      setSnackbarKey((prevKey) => prevKey + 1);
      setLoading(false);
      return;
    }

    // console.log("inside message xradio", xradio);

    navigate("/thumbsUp", { state: iresponse.data[0] });

    setSnackbarMessage("you are now logged in");
    setSnackbarKey((prevKey) => prevKey + 1);
    // console.log("Navigating to /connected");
    // navigate("/connected");
    setUrbitKey("");
    setLoading(false);
  };

  return (
    <div>
      <CustomSnackbar key={snackbarKey} message={snackbarMessage} />
      <div>
        {loading && (
          <div className="loader-container">
            <div className="loader"></div>
          </div> // This div acts as a loader placeholder
        )}

        {!loading && (
          <div className="container " style={{ marginTop: "30px" }}>
            <div className="row">
              <div
                className="col-md-7 mx-auto border rounded-4"
                style={{
                  padding: "3rem 2.5rem",
                  //   backgroundColor: "darkkhaki",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  boxShadow: "",
                }}
              >
                {urbitLogo}

                <h1 style={{ fontSize: "28x" }}>
                  Login with existing URBIT ID
                </h1>
                <div className="input-group pt-4 mb-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="urbitId"
                      placeholder="Enter your Urbit Id"
                      onChange={(e) => setUrbitId(e.target.value)}
                    />
                    <label>Enter your Urbit Id</label>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <div className="form-floating ">
                    <input
                      type="password"
                      className="form-control"
                      id="urbitKey"
                      placeholder="Enter your Credential"
                      onChange={(e) => setUrbitKey(e.target.value)}
                    />
                    <label>Enter your Credential</label>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "10px",
                  }}
                >
                  <button
                    type="submit"
                    style={{ width: "200px" }}
                    className="btn btn-lg  btn-primary px-4"
                    onClick={(e) => __submit1(e)}
                  >
                    Login
                  </button>
                </div>
                <a
                  className="pt-4"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/signup");
                  }}
                >
                  don't have urbit id?
                </a>
              </div>
            </div>
          </div>
        )}
        {/* <CustomSnackbar message={snackbarMessage} /> */}
      </div>
    </div>
  );
};

export default DemoLogin;
