import React, { useState, useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-calendar/dist/Calendar.css";
import "./index.css";
import JFooter from "./JFooter";
import JApp from "./JApp";
import Test from "./Test";
import { URLPREFIX } from "./constant";
import { MetaMaskProvider } from "@metamask/sdk-react";
import Signup from "./Signup";
import TableComponent from "./tableComponent/TableComponent";
import Dashboard from "./components/Dashboard/Dashboard";
import LoginProvider from "./components/LoginProvider/LoginProvider";
import Web3Wallet from "./components/Web3Wallet/Web3Wallet";
import Web3Login from "./components/Web3Login/Web3Login";
import SuccessfullyConnected from "./components/SuccessfullyConnected/SuccessfullyConnected";
import DemoLogin from "./components/DemoLogin/DemoLogin";
import ThumbsUp from "./components/ThumbsUp/ThumbsUp";
import { PrivateRoute } from "./helperFunctions/PrivateRoutes";

const Root = () => {
  return (
    <>
      <BrowserRouter>
        {/* <div style={{ minHeight: "480px" }}>
          <MetaMaskProvider
            debug={false}
            sdkOptions={{
              checkInstallationImmediately: true,
              dappMetadata: {
                name: "Urbit Domain Mapping",
                url: window.location.host,
              },
            }}
          > */}
        <Routes>
          {/* <Route path={URLPREFIX + "/"} element={<Dashboard />} /> */}
          <Route path={URLPREFIX + "/"} element={<DemoLogin />} />
          <Route path={URLPREFIX + "/thumbsUp"} element={<ThumbsUp />} />
          {/* <Route path={URLPREFIX + "/Web3Wallet"} element={<Web3Wallet />} />
          <Route path={URLPREFIX + "/Web3Login"} element={<Web3Login />} />
          <Route
            path={URLPREFIX + "/connected"}
            element={<SuccessfullyConnected />}
          />
          <Route
            path={URLPREFIX + "/LoginProvider"}
            element={<LoginProvider />}
          />
          <Route path={URLPREFIX + "/home"} element={<JApp />} />
          <Route path={URLPREFIX + "/test"} element={<Test />} />
          <Route path={URLPREFIX + "/home/messaging"} element={<JApp />} />
          <Route path={URLPREFIX + "/signup"} element={<Signup />} />
          <Route path={URLPREFIX + "/table"} element={<TableComponent />} /> */}

          {/* <Route
            path={URLPREFIX + "/private"}
            element={
              <PrivateRoute>
                <TableComponent />
              </PrivateRoute>
            }
          /> */}
        </Routes>
        {/* </MetaMaskProvider>
        </div> */}
      </BrowserRouter>
    </>
  );
};
export default Root;
